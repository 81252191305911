import { Menu } from '@headlessui/react';
import { mdiGhost, mdiGhostOffOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Tooltip, trans } from '@webfox/webfox-ui';
import MenuItem from 'Components/MenuItem';
import MenuItemsWrapper from 'Components/MenuItemsWrapper';
import * as React from 'react';
import { useState } from 'react';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const AccountMenu = ({ accountMenuItems }) => {
  const { avatar, name, is_ghosting } = useUser();
  const [trigger, setTriggerElement] = useState(null);

  return (
    <div className="relative inline-block shrink-0 text-left">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button ref={setTriggerElement} className="focus-ring flex max-w-lg items-center rounded text-sm">
              <div aria-haspopup="true" aria-label="User menu" className="relative flex max-w-full items-center text-base" id="user-menu">
                <img className="h-10 w-10 rounded-full" src={avatar} alt={`${name} avatar`} />
                {is_ghosting && (
                  <Tooltip content="You are Ghosting">
                    <div className="text-primary absolute -left-2 -top-2 rounded-full bg-white p-1">
                      <Icon path={mdiGhost} className="w-4" />
                    </div>
                  </Tooltip>
                )}
              </div>
            </Menu.Button>

            <MenuItemsWrapper show={open} trigger={trigger} width="w-64 mb-2" grouped>
              {accountMenuItems?.map((item, index) => (
                <MenuItem icon={item.icon} href={item.href} key={index}>
                  {item.title}
                </MenuItem>
              ))}
              {is_ghosting && (
                <MenuItem icon={mdiGhostOffOutline} href={route('ghost.user.stop')}>
                  {trans('ui.account_menu.stop_ghosting', {}, 'Stop Ghosting')}
                </MenuItem>
              )}
            </MenuItemsWrapper>
          </>
        )}
      </Menu>
    </div>
  );
};

export default AccountMenu;
