import { mdiAccount, mdiHome, mdiLogout } from '@mdi/js';
import route from 'ziggy-js';

export const navItems = () =>
  [
    {
      title: 'Users',
      href: route('users.index'),
      icon: mdiAccount,
      active: 'users.*',
    },
  ].filter((item) => typeof item.filter !== 'function' || item.filter());

export const accountMenuItems = [
  {
    title: 'Log out',
    href: route('logout'),
    icon: mdiLogout,
  },
];
