import {Menu, Transition} from '@headlessui/react';
import cls from 'classnames';
import Portal from 'Components/Portal';
import React, {useState} from 'react';
import {usePopper} from 'react-popper';

const MenuItemsWrapper = ({children, show, trigger, grouped = false, width = 'w-36', padding = 'py-1'}) => {
  const [popper, setPopperElement] = useState();
  const {styles, attributes} = usePopper(trigger, popper, {
    placement: 'bottom-end',
    strategy: 'absolute',
  });

  if (!trigger) return null;

  return (
    <Portal>
      <div className={cls('z-10', width)} ref={setPopperElement} style={styles.popper}{...attributes.popper}>
        <Transition
          show={show}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Menu.Items static className={cls('mt-2.5 divide-y divide-gray-100 rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 outline-none', width)}>
            {grouped ? children : (<div className={padding}>{children}</div>)}
          </Menu.Items>
        </Transition>
      </div>
    </Portal>
  );
};

export default MenuItemsWrapper;
