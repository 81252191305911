import prettyBytes from 'pretty-bytes';
import * as Yup from 'yup';

/**
 * Yup's default url validation only allows http:// https:// or ftp://
 * By using the browsers URL constructor we allow a much broader range of URLs
 */
Yup.addMethod(Yup.string, 'url', function (message) {
  return this.test({
    message,
    name: 'url',
    test(value) {
      try {
        if (value === null || value === undefined) return true;
        new URL(value);
        return true;
      } catch (e) {
        return false;
      }
    },
  });
});

Yup.addMethod(Yup.mixed, 'image', function (message) {
  return this.test({
    message: () => message || 'Invalid filetype, png, jpg, or webp files only',
    name: 'image',
    test(file) {
      if (!file) return true;

      return ['image/png', 'image/jpeg', 'image/webp'].includes(file.type);
    },
  });
});

Yup.addMethod(Yup.mixed, 'video', function (message) {
  return this.test({
    message: () => message || 'Invalid filetype, mp4, webm, ogv, mov or avi files only',
    name: 'video',
    test(file) {
      if (!file) return true;

      return file.type.startsWith('video/');
    },
  });
});

Yup.addMethod(Yup.mixed, 'maxSizeMB', function (size, message) {
  return this.test({
    message: ({value}) => message || `File is too large (${prettyBytes(value.size)}). Maximum allowed ${prettyBytes(size * 1000 * 1000)}`,
    name: 'maxSizeMB',
    test(file) {
      if (!file || !file.size) return true;

      return file.size > size;
    },
  });
});