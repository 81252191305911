import {arrayMoveImmutable as arrayMove} from 'array-move';
import React, {createContext, useMemo, useState} from 'react';

export const DataTableContext = createContext({});
DataTableContext.displayName = 'DataTableContext';

export const DataTableContextProvider = ({children}) => {
  const [filterMethods, setFilterMethods] = useState([]);
  const [sourceRows, setSourceRows] = useState([]);
  const [filterValues, setFilterValues] = useState({});

  const filteredRows = useMemo(() => filterMethods.reduce((rows, method) => {
    return rows.filter(row => method(row, filterValues));
  }, sourceRows), [sourceRows, filterMethods, filterValues]);

  const isFiltered = useMemo(() => {
    return sourceRows.length !== filteredRows.length;
  }, [sourceRows, filteredRows]);

  return (<DataTableContext.Provider value={{
    addFilter: (method) => setFilterMethods((methods) => [...methods, method]),
    setFilterValues: setFilterValues,
    setFilterValue: (key, value) => setFilterValues(filters => ({
      ...filters,
      [key]: value,
    })),
    moveRow: (from, to) => setSourceRows(arrayMove(sourceRows, from, to)),
    setSource: setSourceRows,
    rows: filteredRows,
    filterValues: filterValues,
    isFiltered: isFiltered,
  }}>
    {children}
  </DataTableContext.Provider>);
};